import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../../components/seo/seo';

/**
 * Podcasts index page (/podcasts/)
 * @param {object} data GraphQL data
 */
export default function PodcastsIndexPage({ data }) {
  const podcasts = data.allShowsMetaJson.edges;

  // <pre>
  // { JSON.stringify(data, null, 2) }
  // </pre>

  return (
    <>
      <SEO title="Podcasts landing page" />
      <h1 className="headingPage">Podcasts</h1>

      <p className="intro">
        Choose a podcast to access its list of episodes and learn more about the
        show.
      </p>

      <ul className="podcasts">
        {podcasts.map((podcast) => {
          return (
            <li key={podcast.node.id}>
              <Link to={`/podcasts/${podcast.node.id}/`}>
                <Img
                  fluid={podcast.node.logo.childImageSharp.fluid}
                  alt={podcast.node.title}
                />
                <span className="visuallyHidden">{podcast.node.title}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

// Get meta data for all podcasts
export const podcastsIndexPageQuery = graphql`
  query podcastsQuery {
    allShowsMetaJson(sort: { fields: titleAlpha, order: ASC }) {
      edges {
        node {
          id
          title
          titleAlpha
          ...Logo
        }
      }
    }
  }
`;

// -------------- SCRAPS -----------

// sizes(maxWidth: 600) {
//   ...GatsbyImageSharpSizes
// }

// {/* <aside>
// <h2>Recent Episodes</h2>
// <ul>
// {
//   recentEpisodes.map((show, index) => {
//     return (
//       <li key={index}>
//         <b>{show.node.pubDate}:</b><br /> {show.node.title}
//       </li>
//     )
//   })
// }
// </ul>
// </aside> */}
